<script>
    import Vue from 'vue';
    import BaseVue from '@frontend/Base.vue';
    import Gen from '../helper/Gen';
    Vue.component("Header",()=>import("@frontend/part/Header2.vue"))

    export default {
        extends: BaseVue,
        data() {
            return {
            	data:[],
            	search_keyword:'',
            	filter: {skip:0},
            	loadingLoadmore: false,
            	page404:false,
            }
        },
        async mounted() {
        	await this.ready();

            this.$set(this.$root, 'page', this);
            Vue.component("Header",()=>import("@frontend/part/Header2.vue"))
            if (typeof SEMICOLON != "undefined") SEMICOLON.slider.init()
            setTimeout(() => {
            	$('[data-toggle="tooltip"]').tooltip();
                SEMICOLON.documentOnReady.init()
            }, 300)
            $(document).ready(() => {
                AOS.init()
            })
            this.apiGetCustomerActivitiesList()

            // infinite
            $(window).scroll(()=>{
		      	if(this.loadingLoadmore||this.loadmoreDone) return;
		      	var scrollPosition = $(window).scrollTop() + $(window).height() + 300;
		      	if($(".loadmore").length){
			        if(scrollPosition>$(".loadmore").offset().top){
			          	this.filter.skip = this.data.length
			          	this.loadingLoadmore = true
			          	this.apiGetCustomerActivitiesList((resp)=>{
				            this.loadingLoadmore = false
				            if(!resp.length) return this.loadmoreDone = true
				            this.data = this.data.concat(resp)
			          	})
			        }
		      	}
		    })
        },
        methods: {
        	apiGetCustomerActivitiesList(callBack=null){
        		Gen.apirest('/result-search/'+this.$route.params.q, {filter:this.filter,skip:this.filter.skip}, (err, resp)=>{
              		if(err) console.log(err)
              		if(resp.code==404)return this.page404=true
              		if(callBack) return callBack(resp)
              		this.data=resp.data
              		this.search_keyword=resp.search_keyword
              		this.$root.topProgress.done()
	          	})
        	}
        },
        watch:{
        	"$route.params"(v){
        	 	console.log(v)
        	}
        }
    };
</script>
<template>
  	<div Content>
  		<Component404 v-if="page404"></Component404>
		<div v-else>
	  		<Header></Header>
	  		<section id="page-title" class="page-title-mini">
			    <div class="container clearfix">
			        <h1 class="d-none d-md-block">&nbsp;</h1>
			        <ol class="breadcrumb">
			            <li class="breadcrumb-item"><router-link :to="{name:'index'}">{{ web.mn_home }}</router-link></li>
			            <li class="breadcrumb-item active" aria-current="page">{{ search_keyword.split("%20").join(" ") }}</li>
			        </ol>
			    </div>
			</section>
			<section id="content" class="overflow-none">
				<div class="content-wrap">
					<section class="section nobottommargin notopmargin">
						<div class="container" v-if="data.length==0">
							<div class="row justify-content-center">
								<div class="col-lg-12">
									<div class="detail_article">
										<h2 class="text-center">Data tidak ditemukan.</h2>
									</div>
								</div>
							</div>
						</div>
						<div class="container" v-else>
							<div class="row">
								<div v-for="(v,k) in data" class="col-md-6 col-lg-4">
									<div class="front-news">
										<div class="fn-img">
											<router-link :to="{name:'DetailCustomerActivities',params:{slug:v.ap_slug}}">
												<router-link v-if="v.ap_cat==1" :to="{name:'JurnalDetail',params:{slug:v.ap_slug}}">
													<VImg v-if="!isMobile" :src="uploader(v.ap_photo,'400')" :alt="v.ap_title" :title="v.ap_title"></VImg>
													<VImg v-else :src="uploader(v.ap_photo,'330')" :alt="v.ap_title" :title="v.ap_title"></VImg>
												</router-link>

												<router-link v-if="v.ap_cat==2" :to="{name:'ArticleDetail',params:{slug:v.ap_slug}}">
													<VImg v-if="!isMobile" :src="uploader(v.ap_photo,'400')" :alt="v.ap_title" :title="v.ap_title"></VImg>
													<VImg v-else :src="uploader(v.ap_photo,'330')" :alt="v.ap_title" :title="v.ap_title"></VImg>
												</router-link>

												<router-link v-if="v.ap_cat==3" :to="{name:'DetailCustomerActivities',params:{slug:v.ap_slug}}">
													<VImg v-if="!isMobile" :src="uploader(v.ap_photo,'400')" :alt="v.ap_title" :title="v.ap_title"></VImg>
													<VImg v-else :src="uploader(v.ap_photo,'330')" :alt="v.ap_title" :title="v.ap_title"></VImg>
												</router-link>													
											</router-link>
										</div>
										<div class="fn-desc min_height_132">
											<h4 class="fnd-title" v-if="v.length_title>'6'">
												<router-link v-if="v.ap_cat==1" :to="{name:'JurnalDetail',params:{slug:v.ap_slug}}" v-tooltip="v.ap_title">{{v.ap_title.limitWord(6)}}</router-link>
												
												<router-link v-if="v.ap_cat==2" :to="{name:'ArticleDetail',params:{slug:v.ap_slug}}" v-tooltip="v.ap_title">{{v.ap_title.limitWord(6)}}</router-link>
												
												<router-link v-if="v.ap_cat==3" :to="{name:'DetailCustomerActivities',params:{slug:v.ap_slug}}" v-tooltip="v.ap_title">{{v.ap_title.limitWord(6)}}</router-link>
											</h4>
											<h4 class="fnd-title" v-else>
												<router-link v-if="v.ap_cat==1" :to="{name:'JurnalDetail',params:{slug:v.ap_slug}}">{{ v.ap_title }}</router-link>
												
												<router-link v-if="v.ap_cat==2" :to="{name:'ArticleDetail',params:{slug:v.ap_slug}}">{{ v.ap_title }}</router-link>
												
												<router-link v-if="v.ap_cat==3" :to="{name:'DetailCustomerActivities',params:{slug:v.ap_slug}}">{{v.ap_title.limitWord(6)}}</router-link>
											</h4>

											<h5 class="fnd-name" v-if="v.length_name>'15'" data-toggle="tooltip" :title="v.ap_author_name">
												<i class="icon-user1"></i>
												{{v.ap_author_name.limitChar(15)}}
											</h5>
											<h5 class="fnd-name" v-else>
												<i class="icon-user1"></i>
												{{v.ap_author_name}}
											</h5>
											<span class="fnd-date">
												<i class="icon-calendar1"></i>
												{{(v.ap_publish_date).dates('format')}}
											</span>
										</div>
									</div>
								</div>
								<div class="loadmore col-md-12"><LoadingSpinnerFe v-if="loadingLoadmore" light></LoadingSpinnerFe></div>
							</div>
						</div>
					</section>
				</div>
			</section>
		</div>
  	</div>
</template>